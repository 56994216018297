<template>
  <div class="data-manage flexible">
    <div class="main-content">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.log") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
      </div>
      <div class="data-content">
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="logList"
            :empty-text="$t('no_data')"
            style="width: 100%"
            header-row-class-name="thead-dark"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              :label="$t('_data.all')"
              width="60"
            ></el-table-column>
            <el-table-column
              prop="role_name"
              :label="$t('log.account_type')"
            >
            </el-table-column>
            <el-table-column
              prop="department_name"
              :label="$t('student.department')"
            ></el-table-column>
            <el-table-column
              prop="doctor_name"
              :label="$t('student.doctor')"
            ></el-table-column>
            <el-table-column
              prop="realname"
              :label="$t('log.operator')"
            ></el-table-column>
            <el-table-column
              prop="email"
              :label="$t('log.email')"
            ></el-table-column>
            <el-table-column
              prop="op_name"
              :label="$t('log.op_name')"
            ></el-table-column>
            <el-table-column
              prop="op_res"
              :label="$t('log.op_result')"
            ></el-table-column>
            <el-table-column
              prop="created_at"
              :label="$t('log.op_time')"
            ></el-table-column>
            <!-- <el-table-column :label="$t('operate')" align="right">
              
            </el-table-column> -->
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <pagination
            v-if="totalCount > 0"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <div class="dialog-wrapper" v-if="dialogVisible">
      <div class="dialog-content">
        <div class="dialog-header">
          <span class="dialog-title">{{ $t("_data.tips") }}</span>
          <button
            type="button"
            class="dialog-headerbtn"
            @click="dialogVisible = false"
          >
            <i class="dialog-close el-icon el-icon-close"></i>
          </button>
        </div>
        <div class="dialog-body">
          <span>{{ $t("_data.packing") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logRequest } from "../api/api";
import pagination from "../components/pager";
import { paginationMixin } from "../utils/mixin";
import { Loading } from "element-ui";

export default {
  mixins: [paginationMixin],
  components: {
    pagination
  },
  data() {
    return {
      logList: new Array(),
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      dialogVisible: false,
      multipleSelection: [],
      pageSize: 15,
      currentPage: 1,
      totalCount: 0,
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let params = {
        app_name: "",
        page: this.currentPage,
        pageSize: this.pageSize
      };
      try {
        let res = await logRequest(params);
        if (res.status === 200) {
          this.logList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total;
        } else {
          this.logList = [];
          this.totalCount = 0;
        }
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },
    handleSelectionChange(value) {
      this.multipleSelection = value;
    },
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}
.log-manage {
  .main-content {
    transition: 0.3s ease-in;
  }
  .main-content.active {
    width: calc(~"100% - 220px");
  }
  .el-checkbox {
    margin-bottom: 0;
  }
  .el-table .cell {
    overflow: visible;
  }
  .el-table {
    overflow: visible;
  }
  .el-table__body-wrapper {
    overflow: visible !important;
  }
}
.data-manager-popover {
  .popover-content {
    margin: 120px auto 0;
    width: 700px;
    background: #fff;
  }
}
.dialog-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2003;
  overflow: auto;
  margin: 0;
  background: rgba(0, 0, 0, 0.6);
  .dialog-content {
    position: relative;
    margin: 15vh auto;
    border-radius: 3px;
    width: 600px;
    background: #fff;
  }
  .dialog-header {
    padding: 20px 10px;
    border-bottom: 1px solid #dee2e6;
  }
  .dialog-title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }
  .dialog-headerbtn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
  }
  .dialog-body {
    padding: 30px 0;
    text-align: center;
  }
}

</style>
